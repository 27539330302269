.mdc-radio {
  padding: 8px !important;
}

.mat-badge-content {
  width: 23px !important;
  height: 23px !important;
  line-height: 23px !important;
  background-color: #c00686 !important;
}
