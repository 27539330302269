@use '@angular/material' as mat;
@use './styles/palettes';
@use 'sass:map';

@include mat.all-component-typographies();
@include mat.core();

$theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: palettes.$mx-primary,
    ),
  )
);

// Styles for the docs app that are based on the current theme.
// @mixin theme($theme) {
//   $primary: map.get($theme, primary);
//   $accent: map.get($theme, accent);
//   $warn: map.get($theme, warn);
//   $background: map.get($theme, background);
//   $foreground: map.get($theme, foreground);
// }

// @mixin color($theme) {
//   .primary-color {
//     // Read the 50 hue from the primary color palette.
//     color: mat.get-theme-color($theme, primary, 50);
//   }
// }

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');

$font-family: 'IBM Plex Sans', sans-serif;

html,
body {
  height: 100vh;
  margin: 1px;
  padding: 0;
  font-weight: 400;
}

html,
* {
  @include mat.all-component-themes($theme);
  font-family: $font-family;
}

body {
  font-weight: normal;
  background-color: transparent;
}
